import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardFooter, Icon, Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import { Link } from '../../../../components';

const items = [
  {
    id: 'life_satisfaction',
    title: 'recommendations.library.card.life_satisfaction.title',
    content: 'recommendations.library.card.life_satisfaction.content',
    icon: '/icon-library-life-satisfaction.png',
  },
  {
    id: 'improve_my_mood',
    title: 'recommendations.library.card.improve_my_mood.title',
    content: 'recommendations.library.card.improve_my_mood.content',
    icon: '/icon-library-improve-my-mood.png',
  },
  {
    id: 'quality_connections',
    title: 'recommendations.library.card.quality_connections.title',
    content: 'recommendations.library.card.quality_connections.content',
    icon: '/icon-library-quality-connections.png',
  },
  {
    id: 'feel_more_in_control',
    title: 'recommendations.library.card.feel_more_in_control.title',
    content: 'recommendations.library.card.feel_more_in_control.content',
    icon: '/icon-library-feel-more-in-control.png',
  },
  {
    id: 'take_care_of_yourself',
    title: 'recommendations.library.card.take_care_of_yourself.title',
    content: 'recommendations.library.card.take_care_of_yourself.content',
    icon: '/icon-library-take-care-of-yourself.png',
    classes: 'bg-cover!',
  },
  {
    id: 'financial_wellbeing',
    title: 'recommendations.library.card.financial_wellbeing.title',
    content: 'recommendations.library.card.financial_wellbeing.content',
    icon: '/icon-library-financial-wellbeing.png',
  },
];

function Library() {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-center">
        <div className="flex flex-col items-center mx-auto">
          <h1 className="text-2xl text-gray-900 font-bold text-center">{t('recommendations.library.title')}</h1>
          <p className="text-gray-700 mt-3 md:max-w-[477px] text-center">{t('recommendations.library.content.1')} {t('recommendations.library.content.2')}</p>
        </div>
      </div>
      <div className="flex flex-col gap-6 mt-6 md:flex-row md:flex-wrap">
        {items.map((item) => (
          <Card key={item.id} className="md:w-[350px] lg:w-[450px]">
            <CardBody className="flex grow gap-4">
              <div
                className={`shrink-0 w-16 h-16 rounded-full bg-blue-icon bg-contain bg-center bg-no-repeat ${item.classes ? item.classes : ''}`}
                style={{ backgroundImage: `url(${item.icon})` }}
              />
              <div>
                <Type variant="h3">{t(item.title)}</Type>
                <p className="text-sm mt-3">{t(item.content)}</p>
              </div>
            </CardBody>
            <CardFooter>
              <Link to={`guide/${item.id}`} id={`guide_${item.id}`} className="text-axa-blue-400 font-semibold uppercase text-sm tracking-[1px] items-center flex justify-between w-full px-0 py-0">
                {t('recommendations.library.card.cta')}
                <Icon variant="navigate-next" className="inline fill-axa-blue-400" />
              </Link>
            </CardFooter>
          </Card>
        ))}
      </div>
    </>
  );
}

export default Library;
