import PropTypes from 'prop-types';

function LayoutScreeningSection({ children, hero }) {
  return (
    <div className="flex flex-1 flex-col lg:flex-row">
      {hero}
      <div className="container mx-auto px-10 pt-8 flex flex-col gap-8 md:gap-12 md:mt-12 lg:px-12 lg:pb-8 lg:order-first">
        {children}
      </div>
    </div>
  );
}

LayoutScreeningSection.propTypes = {
  children: PropTypes.node,
  hero: PropTypes.node,
};

export default LayoutScreeningSection;
