import { forwardRef } from 'react';
import { useHref, useLinkClickHandler } from 'react-router';
import useReport from '../../hooks/use-report';

const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i;

const Link = forwardRef(
  (
    {
      onClick,
      replace = false,
      state,
      target,
      to,
      href,
      id,
      ...rest
    },
    ref,
  ) => {
    const { report } = useReport();
    const fireReport = async (data) => report(data);
    const toHref = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });
    let absoluteHref;

    if (typeof to === 'string' && ABSOLUTE_URL_REGEX.test(to)) {
      absoluteHref = to;
    }

    if (typeof href === 'string' && ABSOLUTE_URL_REGEX.test(href)) {
      absoluteHref = href;
    }

    return (
      <a
        {...rest}
        id={id}
        href={absoluteHref || toHref}
        onClick={async (event) => {
          onClick?.(event, absoluteHref !== undefined);

          if (!event.defaultPrevented) {
            if (id) {
              const action = {
                action: `click[${id}]`,
              };

              // // If the link is external we need to wait for a response from the
              // // reporting endpoint to ensure it's tracked before navigation.
              // // Otherwise, it's just fire and forget.
              if (absoluteHref !== undefined) {
                await fireReport(action);
              } else {
                fireReport(action);
              }
            }

            if (absoluteHref === undefined) {
              handleClick(event);
            }
          }
        }}
        ref={ref}
        target={target}
      />
    );
  },
);

export default Link;
