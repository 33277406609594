function HistoryTable({ columns, rows, rowModel }) {
  return (
    <table className="w-full border border-collapse border-axa-blue-400">
      <thead>
        <tr className="bg-axa-ocean-200 text-white">
          {columns.map((column) => (
            <th className="p-3 border-r border-axa-blue-400">{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows !== undefined && rows.length > 0
          ? rows.map((item) => (
            <tr>
              {rowModel.map((model) => (
                <td className="p-3 border border-axa-blue-400">{model(item)}</td>
              ))}
            </tr>
          ))
        : null}
      </tbody>
    </table>
  );
}

export default HistoryTable;
