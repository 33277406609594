import PropTypes from 'prop-types';
import classnames from 'classnames';

function Progress({
  total,
  current,
  label,
  className,
  barClassName,
}) {
  const arr = [...Array(total).keys()];
  const classes = classnames(['flex gap-0.5 md:gap-1', { 'mt-2': label !== undefined }, className]);

  return (
    <div>
      {label !== undefined ? (
        <p className="uppercase text-left text-gray-700 text-xs font-semibold md:text-sm">{label}</p>
      ) : null}
      <div className={classes}>
        {arr.map((i) => {
          const containerClasses = classnames('h-1.5 w-full bg-gray-200 md:h-2', barClassName);
          const barClasses = classnames('h-full bg-axa-blue-400 transition-all ease-out duration-500', {
            'w-full': i < current,
            'w-0': i >= current,
          });

          return (
            <div key={i} className={containerClasses}>
              <div className={barClasses} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

Progress.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  barClassName: PropTypes.string,
};

export default Progress;
