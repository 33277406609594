export const ScreeningConfig = {
  dmhi: {
    title: 'local.screening.dmhi.title',
    description: 'local.screening.dmhi.description',
    cta: 'local.screening.dmhi.cta',
    icon: '/bg-hero-work-security-1.png',
    history: {
      subtitle: 'history.tabs.subtitle',
      columns: [
        'history.table.date',
        'history.table.score',
        'history.table.results',
      ],
    },
    results: {
      title: 'results.title',
      expand: true,
      key: 'MHI',
      boundary: 60,
      recommendationType: 'page',
      recommendationThreshold: Infinity,
      recommendationLimit: Infinity,
      actionsHeader: [
        {
          type: 'link',
          content: 'consent.get_help_now',
          props: {
            id: 'get_help_now',
            to: '/safeguarding',
            variant: 'outline-primary',
          },
        },
      ],
      actionsFooter: [
        {
          type: 'link',
          content: 'results.do_next',
          props: {
            id: 'what_you_can_do_next',
            to: 'recommendations',
          },
        },
      ],
      factors: [
        {
          id: 'csom',
          key: 'CSOM',
          title: 'screening.global.section.csom.title',
          subtitle: 'screening.global.section.csom.subtitle',
          cta: 'screening.global.section.csom.cta',
          ctaLink: 'csom',
          ctaGoodLink: 'csom',
          icon: '/bg-hero-csom-1.png',
          bounds: [[0, 20], [21, 60], [61, 100]],
        },
        {
          id: 'sb',
          key: 'SB',
          title: 'screening.global.section.sb.title',
          subtitle: 'screening.global.section.sb.subtitle',
          cta: 'screening.global.section.sb.cta',
          ctaLink: 'sb',
          ctaGoodLink: 'sb',
          icon: '/bg-hero-sb-1.png',
          bounds: [[0, 40], [41, 60], [61, 100]],
        },
        {
          id: 'ls',
          key: 'SCB_2',
          title: 'screening.global.section.ls.title',
          subtitle: 'screening.global.section.ls.subtitle',
          cta: 'screening.global.section.ls.cta',
          ctaLink: 'ls',
          ctaGoodLink: 'ls',
          icon: '/bg-hero-ls-1.png',
          bounds: [[0, 20], [21, 60], [61, 100]],
        },
      ],
    },
  },
  'be-workplace': {
    title: 'local.screening.be-workplace.title',
    description: 'local.screening.be-workplace.description',
    cta: 'local.screening.be-workplace.cta',
    icon: '/bg-hero-work-management-1.png',
    history: {
      subtitle: 'history.tabs.subtitle',
      columns: [
        'history.table.date',
        'history.table.results',
      ],
    },
    results: {
      title: 'screening.be-workplace.results.title',
      desc: 'screening.be-workplace.results.desc',
      expand: false,
      boundary: 50,
      bounds: [[0, 20], [21, 50], [50, 100]],
      percentage: true,
      percentageFactor: 10,
      recommendationType: 'modal',
      recommendationThreshold: 5,
      recommendationLimit: Infinity,
      actionsHeader: [
        {
          type: 'toggle',
          content: [
            'screening.be-workplace.results.toggle.recommendations',
            'screening.be-workplace.results.toggle.support',
          ],
          props: {
          },
        },
      ],
      actionsFooter: [
        {
          type: 'link',
          content: 'screening.be-workplace.results.mha',
          props: {
            id: 'mind_health_assessment',
            to: '/assessment/dmhi',
          },
        },
        {
          type: 'link',
          content: 'screening.be-workplace.results.exit',
          props: {
            id: 'exit',
            to: '/',
            variant: 'outline-primary',
          },
        },
      ],
      factors: [
        {
          id: 'wenv',
          key: 'environment',
          title: 'screening.be-workplace.results.subfactor.environment.title',
          subtitle: 'screening.be-workplace.results.subfactor.environment.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wenv',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-environment-1.jpeg',
          recommendation: 'screening.be-workplace.subfactor.environment.recommendation',
        },
        {
          id: 'wrel',
          key: 'relationships',
          title: 'screening.be-workplace.results.subfactor.relationships.title',
          subtitle: 'screening.be-workplace.results.subfactor.relationships.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wrel',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-relations-1.jpeg',
          recommendation: 'screening.be-workplace.subfactor.relationships.recommendation',
        },
        {
          id: 'wcomm',
          key: 'communication',
          title: 'screening.be-workplace.results.subfactor.communication.title',
          subtitle: 'screening.be-workplace.results.subfactor.communication.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wcomm',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-communication-1.png',
          recommendation: 'screening.be-workplace.subfactor.communication.recommendation',
        },
        {
          id: 'wdev',
          key: 'development',
          title: 'screening.be-workplace.results.subfactor.development.title',
          subtitle: 'screening.be-workplace.results.subfactor.development.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wdev',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-development-1.png',
          recommendation: 'screening.be-workplace.subfactor.development.recommendation',
        },
        {
          id: 'wflex',
          key: 'flexibility',
          title: 'screening.be-workplace.results.subfactor.flexibility.title',
          subtitle: 'screening.be-workplace.results.subfactor.flexibility.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wflex',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-conditions-1.jpeg',
          recommendation: 'screening.be-workplace.subfactor.flexibility.recommendation',
        },
        {
          id: 'wsec',
          key: 'security',
          title: 'screening.be-workplace.results.subfactor.security.title',
          subtitle: 'screening.be-workplace.results.subfactor.security.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wsec',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-security-1.png',
          recommendation: 'screening.be-workplace.subfactor.security.recommendation',
        },
        {
          id: 'wload',
          key: 'workload',
          title: 'screening.be-workplace.results.subfactor.workload.title',
          subtitle: 'screening.be-workplace.results.subfactor.workload.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wload',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-content-1.jpeg',
          recommendation: 'screening.be-workplace.subfactor.workload.recommendation',
        },
        {
          id: 'wmean',
          key: 'meaning',
          title: 'screening.be-workplace.results.subfactor.meaning.title',
          subtitle: 'screening.be-workplace.results.subfactor.meaning.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wmean',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-meaning-1.png',
          recommendation: 'screening.be-workplace.subfactor.meaning.recommendation',
        },
        {
          id: 'wrole',
          key: 'role',
          title: 'screening.be-workplace.results.subfactor.role.title',
          subtitle: 'screening.be-workplace.results.subfactor.role.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wrole',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-role-1.png',
          recommendation: 'screening.be-workplace.subfactor.role.recommendation',
        },
        {
          id: 'winst',
          key: 'instructions',
          title: 'screening.be-workplace.results.subfactor.instructions.title',
          subtitle: 'screening.be-workplace.results.subfactor.instructions.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'winst',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-instructions-1.png',
          recommendation: 'screening.be-workplace.subfactor.instructions.recommendation',
        },
        {
          id: 'wdec',
          key: 'decisions',
          title: 'screening.be-workplace.results.subfactor.decisions.title',
          subtitle: 'screening.be-workplace.results.subfactor.decisions.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wdec',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-organisation-1.jpeg',
          recommendation: 'screening.be-workplace.subfactor.decisions.recommendation',
        },
        {
          id: 'wman',
          key: 'management',
          title: 'screening.be-workplace.results.subfactor.management.title',
          subtitle: 'screening.be-workplace.results.subfactor.management.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wman',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-management-1.png',
          recommendation: 'screening.be-workplace.subfactor.management.recommendation',
        },
        {
          id: 'wval',
          key: 'values',
          title: 'screening.be-workplace.results.subfactor.values.title',
          subtitle: 'screening.be-workplace.results.subfactor.values.subtitle',
          cta: 'screening.be-workplace.results.subfactor.cta',
          ctaLink: 'wval',
          ctaGood: 'screening.be-workplace.results.subfactor.cta-good',
          icon: '/bg-hero-work-values-1.png',
          recommendation: 'screening.be-workplace.subfactor.values.recommendation',
        },
      ],
    },
  },
};
