import { AuthConfig, Authorization, LocalStorageAdapter } from '@mindstep-axa-dmhi/dmhi-frontend-auth';
import { redirect } from 'react-router';
import API from '../../../../services/api';

const requiresAuth = import.meta.env.VITE_AUTH_REQUIRED;

// Loader for the callback route. Performs an authorization code grant and saves
// tokens in local storage.
async function loader() {
  if (requiresAuth === undefined || requiresAuth === 'false') {
    return redirect('/');
  }

  const org = window.sessionStorage.getItem('org');
  let server;
  let clientId;
  let orgId;

  // By default we use the auth server details for AXA from the env. Because
  // the app has been designed in a single tenant manner each AXA entity has
  // its own set of environment variables for its own specific deployment. If
  // an `org` param was set we use a different auth server. This is to allow
  // development and test environments to operate independently of AXA.
  switch (org) {
    case 'mindstep':
      server = new URL(import.meta.env.VITE_AUTH_HOST_MINDSTEP);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_MINDSTEP;
      orgId = import.meta.env.VITE_AUTH_ORG_ID_MINDSTEP;
      break;
    default:
      server = new URL(import.meta.env.VITE_AUTH_HOST_AXA);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_AXA;
  }

  const authConfig = new AuthConfig(server, clientId);
  await authConfig.init();

  const auth = new Authorization(
    authConfig,
    {
      organization: orgId,
    },
    new LocalStorageAdapter(),
  );

  await auth.authorizationCodeGrant(new URL(window.location.href));
  API.configure();

  return redirect('/');
}

export default loader;
