import { useNavigate } from 'react-router';
import { Icon, Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import useTranslate from '../../../../hooks/use-translate';
import { Button } from '../../../../components';

function TermsOfUseBE() {
  const navigate = useNavigate();
  const { trans, t } = useTranslate([
    'tou.be.content.1',
    'tou.be.content.2',
    'tou.be.content.3',
    'tou.be.content.4',
    'tou.be.content.5',
    'tou.be.content.6',
    'tou.be.content.7',
    'tou.be.content.8',
    'tou.be.content.9',
    'tou.be.content.10',
    'tou.be.content.11',
    'tou.be.content.12',
    'tou.be.content.13',
    'tou.be.content.14',
    'tou.be.content.15',
    'tou.be.content.16',
    'tou.be.content.17',
    'tou.be.content.18',
    'tou.be.content.19',
    'tou.be.content.20',
    'tou.be.content.21',
    'tou.be.content.22',
    'tou.be.content.23',
    'tou.be.content.24',
    'tou.be.content.25',
    'tou.be.content.26',
    'tou.be.content.27',
    'tou.be.content.28',
    'tou.be.content.29',
    'tou.be.content.30',
    'tou.be.content.31',
    'tou.be.content.32',
    'tou.be.content.33',
    'tou.be.content.34',
    'tou.be.content.35',
    'tou.be.content.36',
    'tou.be.content.37',
    'tou.be.content.38',
    'tou.be.content.39',
    'tou.be.content.40',
    'tou.be.content.41',
    'tou.be.content.42',
    'tou.be.content.43',
    'tou.be.content.44',
    'tou.be.content.45',
  ]);

  return (
    <div className="container mx-auto pt-8 md:pt-16 md:pb-16 px-4">
      <Button
        variant="link"
        onClick={(e) => { e.preventDefault(); navigate(-1); }}
        tabIndex="-1"
      >
        <Icon variant="navigate-before" className="inline fill-gray-800 mr-4" />
        {t('back')}
      </Button>

      <div className="flex flex-col mt-12">
        <Type variant="h1" className="uppercase">{trans['tou.be.content.1']}</Type>
        <div className="flex flex-col gap-6 mt-8">
          <p>{trans['tou.be.content.2']}</p>
          <p>{trans['tou.be.content.3']}</p>
          <p>{trans['tou.be.content.4']}</p>
          <p>{trans['tou.be.content.5']}</p>
          <p>{trans['tou.be.content.6']}</p>
          <p>{trans['tou.be.content.7']}</p>
          <Type variant="h2" className="uppercase mt-2">{trans['tou.be.content.8']}</Type>
          <p>{trans['tou.be.content.9']}</p>
          <p>{trans['tou.be.content.10']}</p>
          <p>{trans['tou.be.content.11']}</p>
          <Type variant="h2" className="uppercase mt-2">{trans['tou.be.content.12']}</Type>
          <p>{trans['tou.be.content.13']}</p>
          <p>{trans['tou.be.content.14']}</p>
          <p>{trans['tou.be.content.15']}</p>
          <Type variant="h2" className="uppercase mt-2">{trans['tou.be.content.16']}</Type>
          <p>{trans['tou.be.content.17']}</p>
          <p>{trans['tou.be.content.18']}</p>
          <p>{trans['tou.be.content.19']}</p>
          <p>{trans['tou.be.content.20']}</p>
          <p>{trans['tou.be.content.21']}</p>
          <Type variant="h2" className="uppercase mt-2">{trans['tou.be.content.22']}</Type>
          <p className="font-bold">{trans['tou.be.content.23']}</p>
          <p>{trans['tou.be.content.24']}</p>
          <p>{trans['tou.be.content.25']}</p>
          <p>{trans['tou.be.content.26']}</p>
          <p>{trans['tou.be.content.27']}</p>
          <p className="font-bold">{trans['tou.be.content.28']}</p>
          <p>{trans['tou.be.content.29']}</p>
          <p>{trans['tou.be.content.30']}</p>
          <p>{trans['tou.be.content.31']}</p>
          <p className="font-bold">{trans['tou.be.content.32']}</p>
          <p>{trans['tou.be.content.33']}</p>
          <p>{trans['tou.be.content.34']}</p>
          <p>{trans['tou.be.content.35']}</p>
          <p className="font-bold">{trans['tou.be.content.36']}</p>
          <p>{trans['tou.be.content.37']}</p>
          <p>{trans['tou.be.content.38']}</p>
          <p>{trans['tou.be.content.39']}</p>
          <p>{trans['tou.be.content.40']}</p>
          <p>{trans['tou.be.content.41']}</p>
          <p className="font-bold">{trans['tou.be.content.42']}</p>
          <p>{trans['tou.be.content.43']}</p>
          <p className="font-bold">{trans['tou.be.content.44']}</p>
          <p>{trans['tou.be.content.45']}</p>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUseBE;
