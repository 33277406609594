import { redirect } from 'react-router';
import { AuthConfig, Authorization, LocalStorageAdapter } from '@mindstep-axa-dmhi/dmhi-frontend-auth';
import { validLocales } from '../../i18n';

const requiresAuth = import.meta.env.VITE_AUTH_REQUIRED;

// Loader for the root route. If the tenant requires authentication we generate
// an authorization URL for OpenID Connect and redirect.
async function loader({ params, request }) {
  // Redirect invalid locales to the root, and secondary locales to preferred
  // locales.
  if (params.lang !== undefined) {
    const match = validLocales[params.lang.toLowerCase()];

    if (match === undefined) {
      return redirect('/');
    }

    if (match.redirect) {
      return redirect(`/${match.redirect}`);
    }
  }

  if (requiresAuth === undefined || requiresAuth === 'false') {
    return null;
  }

  const url = new URL(request.url);
  const orgStored = window.sessionStorage.getItem('org');
  const orgParam = url.searchParams.get('org');
  let org = orgStored;
  let server;
  let clientId;
  let orgId;
  let audience;
  let scope;

  if (orgStored === undefined || orgStored === '' || (orgParam && orgStored !== orgParam)) {
    window.sessionStorage.setItem('org', orgParam ?? '');
    org = orgParam;
  }

  // Tenants can provide parameters to the service via the query string. The
  // query param should be named "params" and the value is stored in session
  // storage so we can access it for the duration of the session.
  const storedTenantParams = window.sessionStorage.getItem('tenant_params');

  if (storedTenantParams === null) {
    const tenantParams = url.searchParams.get('params') ?? url.searchParams.get('PARAMS');

    if (tenantParams !== null) {
      window.sessionStorage.setItem('tenant_params', tenantParams);
    }
  }

  // By default we use the auth server details for AXA from the env. Because
  // the app has been designed in a single tenant manner each AXA entity has
  // its own set of environment variables for its own specific deployment. If
  // an `org` param was set we use a different auth server. This is to allow
  // development and test environments to operate independently of AXA.
  switch (org) {
    case 'mindstep':
      server = new URL(import.meta.env.VITE_AUTH_HOST_MINDSTEP);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_MINDSTEP;
      orgId = import.meta.env.VITE_AUTH_ORG_ID_MINDSTEP;
      audience = import.meta.env.VITE_AUTH_AUDIENCE_MINDSTEP;
      scope = import.meta.env.VITE_AUTH_SCOPE_MINDSTEP;
      break;
    default:
      server = new URL(import.meta.env.VITE_AUTH_HOST_AXA);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_AXA;
      audience = import.meta.env.VITE_AUTH_AUDIENCE_AXA;
      scope = import.meta.env.VITE_AUTH_SCOPE_AXA;
  }

  const authConfig = new AuthConfig(server, clientId);
  await authConfig.init();

  const auth = new Authorization(
    authConfig,
    {
      redirectURL: import.meta.env.VITE_AUTH_REDIRECT_URL,
      organization: orgId,
      audience,
      scope,
    },
    new LocalStorageAdapter(),
  );

  // If we already have an authorized user we can stop here. If necessary in the
  // future we can use `auth.fetchUserInfo` to access details of the
  // authenticated user and put them in the store.
  if (auth.isValid()) {
    return null;
  }

  const redirectTo = await auth.buildAuthorizationURL();

  return redirect(redirectTo);
}

export default loader;
