import { Link, useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import getPath from '../../utils/path';

function Footer({ content }) {
  const { lang } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white px-8 py-3 text-gray-800 mt-24 lg:mt-0">
        <p className="font-bold italic">{t('footer.privacy.title')}</p>
        {content.map((item) => (
          <p key={item} className="mt-3 text-xs italic">
            <Trans
              i18nKey={item}
              components={{
                Link: <Link to={getPath(lang, '/information')} className="text-axa-blue-400 underline" />,
                a: <a className="text-axa-blue-400 underline" />,
              }}
            />
          </p>
        ))}
      </div>
      <div className="bg-axa-ocean-200 px-8 py-6 text-white text-sm uppercase font-semibold">
        <Link to={getPath(lang, '/information')}>{t('footer.information_notice')}</Link>
      </div>
      <div className="bg-axa-ocean-200 border-t border-t-axa-ocean-100 px-8 py-3">
        <Type className="text-xs text-white">
          Policy Privacy © 2024 AXA All Rights Reserved
        </Type>
      </div>
    </>
  );
}

export default Footer;
