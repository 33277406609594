import PropTypes from 'prop-types';
import { Card, CardBody, CardFooter, Icon, PowerBar, Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import { Link } from '../../../../components';

function SubscoreCard({
  type,
  title,
  subtitle,
  cta,
  link,
  score,
  icon,
  bounds,
  isPercentage,
  onClick,
}) {
  const bound = bounds.findIndex(([x, y]) => score >= x && score <= y);

  return (
    <Card className="w-full md:w-[344px]">
      <CardBody className="flex grow gap-4">
        <div className="shrink-0">
          <img src={icon} alt="" className="w-[61px] h-[61px] object-cover rounded-full" />
        </div>
        <div className="w-full flex flex-col gap-2">
          <Type variant="h3">
            {title}
          </Type>
          <p className="text-sm">
            {subtitle}
          </p>
          <div className="flex gap-2 items-center w-3/4">
            <p className="font-semibold text-axa-blue-100">{score}{isPercentage ? '%' : ''}</p>
            <PowerBar active={bound} score={score} variant="mini" />
          </div>
        </div>
      </CardBody>
      {cta !== undefined ? (
        <CardFooter>
          {link !== undefined ? (
            <Link to={link} onClick={onClick} id={type} className="text-axa-blue-400 text-sm uppercase font-semibold flex justify-between items-center w-full">
              {cta}
              <Icon variant="navigate-next" className="inline fill-axa-blue-400" />
            </Link>
          ) : (
            <span className="text-axa-blue-400 text-sm font-semibold uppercase">{cta}</span>
          )}
        </CardFooter>
      ) : null}
    </Card>
  );
}

SubscoreCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  icon: PropTypes.string,
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  isPercentage: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SubscoreCard;
