import PropTypes from 'prop-types';

function LayoutLanding({ children, hero }) {
  return (
    <div className="flex flex-1 flex-col lg:flex-row relative">
      {hero}
      <div className="container mx-auto px-4 mt-4 flex flex-col gap-8 md:gap-12 md:mt-12 lg:my-28 lg:px-12">
        {children}
      </div>
    </div>
  );
}

LayoutLanding.propTypes = {
  children: PropTypes.node,
  hero: PropTypes.node,
};

export default LayoutLanding;
