import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Type, Modal } from '@mindstep-axa-dmhi/dmhi-frontend-components';

const commonClasses = ['rounded-sm py-3 px-6 flex items-center gap-3 border-gray-400'];

function LanguagesModal({
  languages,
  active,
  isOpen,
  toggle,
}) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={toggle}>
      <Type variant="h1">{t('welcome.change_language')}</Type>
      <div className="flex flex-col gap-3 mt-4">
        <ul className="flex flex-col gap-3">
          {languages.map((lang) => {
            const classes = classnames(commonClasses, {
              'bg-blue-grey': active !== lang.code,
              'bg-white': active === lang.code,
              border: active === lang.code,
            });

            return (
              <li key={lang.name} className={classes}>
                <img src={lang.icon} alt="Icon" loading="lazy" className="w-[33px] h-[33px] rounded-full object-cover shadow-sm" />
                <a href={lang.url} className="block w-full">{lang.name}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
}

LanguagesModal.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  active: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default LanguagesModal;
