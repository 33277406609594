import { Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';

function Item({ title, content }) {
  const list = Array.isArray(content) ? content : [content];

  return (
    <div>
      <Type variant="h3" className="text-axa-blue-400! uppercase">{title}</Type>
      {list.map((c) => <p className="mt-3">{c}</p>)}
    </div>
  );
}

export default Item;
