import PropTypes from 'prop-types';
import { Button } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import useReport from '../../hooks/use-report';

function TrackedButton({ id, onClick, ...props }) {
  const { report } = useReport();
  const fireReport = async (data) => report(data);
  const handleClick = async (e, isExternal) => {
    if (id) {
      const action = {
        action: `click[${id}]`,
      };

      // If the link is external we need to wait for a response from the
      // reporting endpoint to ensure it's tracked before navigation.
      // Otherwise, it's just fire and forget.
      if (isExternal) {
        await fireReport(action);
      } else {
        fireReport(action);
      }
    }

    onClick?.(e);
  };

  return (
    <Button id={id} onClick={handleClick} {...props} />
  );
}

TrackedButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default TrackedButton;
