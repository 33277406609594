import classnames from 'classnames';
import { Link, useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import { Button, Link as TrackedLink } from '../../../../components';

const localServicesClassnames = {
  li: 'bg-white p-4',
  img: 'object-contain! md:w-[140px]! md:h-[140px]!',
};

const Config = {
  default: [
    {
      id: 'list-global',
      type: 'ul',
      content: [
        {
          id: 'list-item-global',
          type: 'list-item-icon',
          icon: '/icon-axa-global-healthcare.png',
          alt: 'AXA Global Healthcare logo',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.service.1.title',
              ],
            },
            'recommendations.support.global.service.1.content',
            {
              id: 'buttons-group-global',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.axaglobalhealthcare.com/en/members/your-services/',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_global_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axaglobalhealthcare.com/en/mind-health-service/',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_global_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-fr',
          type: 'list-item-icon',
          icon: '/icon-flag-fr.png',
          alt: 'Flag of France',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.service.1.title',
              ],
            },
            'recommendations.support.global.fr.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.service.2.title',
              ],
            },
            'recommendations.support.global.fr.service.2.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.service.3.title',
              ],
            },
            'recommendations.support.global.fr.service.3.content',
            {
              id: 'buttons-group-fr',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.angel.fr/',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_fr_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axa.fr/complementaire-sante/service-angel.html',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_fr_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-uk',
          type: 'list-item-icon',
          icon: '/icon-flag-uk.png',
          alt: 'Flag of the United Kingdom',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.uk.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.uk.service.1.title',
              ],
            },
            'recommendations.support.global.uk.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.uk.service.2.title',
              ],
            },
            'recommendations.support.global.uk.service.2.content',
            {
              id: 'buttons-group-uk',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://auth.axahealth.co.uk/pppauth.onmicrosoft.com/b2c_1a_signupsignin_authcodegrant_r3/oauth2/v2.0/authorize?client_id=1f241bd8-1431-44c5-acdf-25f5cf810db8&redirect_uri=https%3A%2F%2Fwww.axahealth.co.uk%2F&response_type=code&scope=openid%20https%3A%2F%2Fpppauth.onmicrosoft.com%2Fhubs%2Fuser_impersonation&state=OpenIdConnect.AuthenticationProperties%3D91D9dImY7mZVm26_PK6-bzRV6nG7vz10jGPZh6SmR1Hlqb-Uh38wiB42Z5Ma3_56BS48EWZWMpdFewljQG1HPXKKShtCX2sZmh1F39AbDbaIrZMsKgcWct5sRHMH3W_UjhLANRvbXrCx3IqmFFAcNzo6JDLvvxBdLjMHaBoPDSdtJFVIXRGbqDK6184sJYvE&response_mode=form_post&nonce=638611214318319142.NDY0MTMwOGYtMzQyMS00NDg1LWI2NDktNTNmMDI1ZGI3MzhmMTFiNTJhNmQtNzI0Zi00YjBiLWEzYmQtYzA0NjhjYzYyYjNm&x-client-SKU=ID_NET461&x-client-ver=5.4.0.0',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_gb_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axahealth.co.uk/staying-healthy/mental-health/',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_gb_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-de',
          type: 'list-item-icon',
          icon: '/icon-flag-de.png',
          alt: 'Flag of Germany',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.service.1.title',
              ],
            },
            'recommendations.support.global.de.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.service.2.title',
              ],
            },
            'recommendations.support.global.de.service.2.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.service.3.title',
              ],
            },
            'recommendations.support.global.de.service.3.content',
            {
              id: 'buttons-group-de',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://meine-gesunde-seele.de/unterstuetzung/',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_de_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://meine-gesunde-seele.de/',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_de_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-it',
          type: 'list-item-icon',
          icon: '/icon-flag-it.png',
          alt: 'Flag of Italy',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.it.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.it.service.1.title',
              ],
            },
            'recommendations.support.global.it.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.it.service.2.title',
              ],
            },
            'recommendations.support.global.it.service.2.content',
            {
              id: 'buttons-group-it',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://login.axa.it/login-dhp?utm_source=mind_health_self_check&utm_medium=referral&utm_campaign=login_dhp',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_it_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://assistenza360.axa.it/?utm_source=mind_health_self_check&utm_medium=referral&utm_campaign=homepage_assistenza360',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_it_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-be',
          type: 'list-item-icon',
          icon: '/icon-flag-be.png',
          alt: 'Flag of Belgium',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.be.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.be.service.1.title',
              ],
            },
            'recommendations.support.global.be.service.1.content',
            {
              id: 'buttons-group-be',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.axa.be/fr/particuliers',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_be_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axa.be/fr/particuliers',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_be_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-ch',
          type: 'list-item-icon',
          icon: '/icon-flag-ch.png',
          alt: 'Flag of Switzerland',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.1.title',
              ],
            },
            'recommendations.support.global.ch.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.2.title',
              ],
            },
            'recommendations.support.global.ch.service.2.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.3.title',
              ],
            },
            'recommendations.support.global.ch.service.3.content',
          ],
        },
        {
          id: 'list-item-tr',
          type: 'list-item-icon',
          icon: '/icon-flag-tr.png',
          alt: 'Flag of Turkey',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.service.1.title',
              ],
            },
            'recommendations.support.global.tr.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.service.2.title',
              ],
            },
            'recommendations.support.global.tr.service.2.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.service.3.title',
              ],
            },
            'recommendations.support.global.tr.service.3.content',
            {
              id: 'buttons-group-tr',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.axasigorta.com.tr/bireysel-islemler',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_tr_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axasigorta.com.tr/saglik-sigortalari',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_tr_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-es',
          type: 'list-item-icon',
          icon: '/icon-flag-es.png',
          alt: 'Flag of Spain',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.es.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.es.service.1.title',
              ],
            },
            'recommendations.support.global.es.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.es.service.2.title',
              ],
            },
            'recommendations.support.global.es.service.2.content',
            {
              id: 'buttons-group-es',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.axa.es/cliente/axa',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_es_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axa.es/servicios/moviles-videoconsulta',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_es_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-mx',
          type: 'list-item-icon',
          icon: '/icon-flag-mx.png',
          alt: 'Flag of Mexico',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.mx.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.mx.service.1.title',
              ],
            },
            'recommendations.support.global.mx.service.1.content',
            {
              id: 'buttons-group-mx',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'tel:+525551693080',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_mx_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://cloud.email-axakeralty.mx/digital-mind-health-index',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_mx_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-jp',
          type: 'list-item-icon',
          icon: '/icon-flag-jp.png',
          alt: 'Flag of Japan',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.ja.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ja.service.1.title',
              ],
            },
            'recommendations.support.global.ja.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.ja.service.2.title',
              ],
            },
            'recommendations.support.global.ja.service.2.content',
            {
              id: 'buttons-group-jp',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.axa.co.jp/customer/emma',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_jp_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axa.co.jp/',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_jp_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-hk',
          type: 'list-item-icon',
          icon: '/icon-flag-hk.png',
          alt: 'Flag of Hong Kong',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.service.1.title',
              ],
            },
            'recommendations.support.global.hk.service.1.content',
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.service.2.title',
              ],
            },
            'recommendations.support.global.hk.service.2.content',
            {
              id: 'buttons-group-hk',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.axa.com.hk/en/mind-charger',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_hk_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axa.com.hk/en/mind-journey-sharing',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_hk_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-th',
          type: 'list-item-icon',
          icon: '/icon-flag-th.png',
          alt: 'Flag of Thailand',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.th.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.th.service.1.title',
              ],
            },
            'recommendations.support.global.th.service.1.content',
            {
              id: 'buttons-group-th',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://www.krungthai-axa.co.th/th/health-services/mind-health',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_th_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.krungthai-axa.co.th/th/health-services/mind-health',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_th_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
        {
          id: 'list-item-ph',
          type: 'list-item-icon',
          icon: '/icon-flag-ph.png',
          alt: 'Flag of Philippines',
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.ph.title',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ph.service.1.title',
              ],
            },
            'recommendations.support.global.ph.service.1.content',
            {
              id: 'buttons-group-ph',
              type: 'button-group',
              content: [
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'outline-primary',
                  href: 'https://axa.mindyou.com.ph/login',
                  content: 'recommendations.support.global.customer_link',
                  id: 'recommendations_global_ph_customer',
                  target: '_blank',
                },
                {
                  type: 'button',
                  buttonType: 'link',
                  variant: 'primary',
                  href: 'https://www.axa.com.ph/products/health-and-critical-illness',
                  content: 'recommendations.support.global.link',
                  id: 'recommendations_global_ph_notcustomer',
                  target: '_blank',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  'en-gb': [
    {
      id: 'list-global',
      type: 'ul',
      content: [
        {
          id: 'list-item-uk',
          type: 'list-item-icon',
          icon: '/icon-support-gb-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.uk.service.1.title',
              ],
            },
            'recommendations.support.global.uk.service.1.content',
          ],
        },
        {
          id: 'list-item-uk-2',
          type: 'list-item-icon',
          icon: '/icon-support-gb-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.uk.service.2.title',
              ],
            },
            'recommendations.support.global.uk.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-uk',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://auth.axahealth.co.uk/pppauth.onmicrosoft.com/b2c_1a_signupsignin_authcodegrant_r3/oauth2/v2.0/authorize?client_id=1f241bd8-1431-44c5-acdf-25f5cf810db8&redirect_uri=https%3A%2F%2Fwww.axahealth.co.uk%2F&response_type=code&scope=openid%20https%3A%2F%2Fpppauth.onmicrosoft.com%2Fhubs%2Fuser_impersonation&state=OpenIdConnect.AuthenticationProperties%3D91D9dImY7mZVm26_PK6-bzRV6nG7vz10jGPZh6SmR1Hlqb-Uh38wiB42Z5Ma3_56BS48EWZWMpdFewljQG1HPXKKShtCX2sZmh1F39AbDbaIrZMsKgcWct5sRHMH3W_UjhLANRvbXrCx3IqmFFAcNzo6JDLvvxBdLjMHaBoPDSdtJFVIXRGbqDK6184sJYvE&response_mode=form_post&nonce=638611214318319142.NDY0MTMwOGYtMzQyMS00NDg1LWI2NDktNTNmMDI1ZGI3MzhmMTFiNTJhNmQtNzI0Zi00YjBiLWEzYmQtYzA0NjhjYzYyYjNm&x-client-SKU=ID_NET461&x-client-ver=5.4.0.0',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_gb_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axahealth.co.uk/staying-healthy/mental-health/',
          content: 'recommendations.support.global.link',
          id: 'recommendations_gb_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  'fr-be': [
    {
      id: 'list-be',
      type: 'ul',
      content: [
        {
          id: 'list-item-be',
          type: 'list-item-icon',
          icon: '/icon-support-be-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.be.service.1.title',
              ],
            },
            'recommendations.support.global.be.service.1.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-be',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axa.be/fr/particuliers',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_be_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.be/fr/particuliers',
          content: 'recommendations.support.global.link',
          id: 'recommendations_be_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  'nl-be': [
    {
      id: 'list-be',
      type: 'ul',
      content: [
        {
          id: 'list-item-be',
          type: 'list-item-icon',
          icon: '/icon-support-be-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.be.service.1.title',
              ],
            },
            'recommendations.support.global.be.service.1.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-be',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axa.be/nl/particulieren',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_nl-be_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.be/nl/particulieren',
          content: 'recommendations.support.global.link',
          id: 'recommendations_nl-be_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  hk: [
    {
      id: 'list-hk',
      type: 'ul',
      content: [
        {
          id: 'list-item-hk',
          type: 'list-item-icon',
          icon: '/icon-support-hk-1.jpg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.service.1.title',
              ],
            },
            'recommendations.support.global.hk.service.1.content',
          ],
        },
        {
          id: 'list-item-2-hk',
          type: 'list-item-icon',
          icon: '/icon-support-hk-2.jpg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.service.2.title',
              ],
            },
            'recommendations.support.global.hk.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-hk',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axa.com.hk/en/mind-charger',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_hk_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.com.hk/en/mind-journey-sharing',
          content: 'recommendations.support.global.link',
          id: 'recommendations_hk_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  'zh-hans-hk': [
    {
      id: 'list-hk',
      type: 'ul',
      content: [
        {
          id: 'list-item-hk',
          type: 'list-item-icon',
          icon: '/icon-support-hk-1.jpg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.service.1.title',
              ],
            },
            'recommendations.support.global.hk.service.1.content',
          ],
        },
        {
          id: 'list-item-2-hk',
          type: 'list-item-icon',
          icon: '/icon-support-hk-2.jpg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.hk.service.2.title',
              ],
            },
            'recommendations.support.global.hk.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-hk',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axa.com.hk/en/mind-charger',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_hk_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.com.hk/en/mind-journey-sharing',
          content: 'recommendations.support.global.link',
          id: 'recommendations_hk_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  de: [
    {
      id: 'list-de',
      type: 'ul',
      content: [
        {
          id: 'list-item-de',
          type: 'list-item-icon',
          icon: '/icon-support-de-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.service.1.title',
              ],
            },
            'recommendations.support.global.de.service.1.content',
          ],
        },
        {
          id: 'list-item-2-de',
          type: 'list-item-icon',
          icon: '/icon-support-de-2.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.service.2.title',
              ],
            },
            'recommendations.support.global.de.service.2.content',
          ],
        },
        {
          id: 'list-item-3-de',
          type: 'list-item-icon',
          icon: '/icon-support-de-3.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.de.service.3.title',
              ],
            },
            'recommendations.support.global.de.service.3.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-de',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://meine-gesunde-seele.de/unterstuetzung/',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_de_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.meine-gesunde-seele.de',
          content: 'recommendations.support.global.link',
          id: 'recommendations_de_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  'fr-ch': [
    {
      id: 'list-ch',
      type: 'ul',
      columns: 3,
      content: [
        {
          id: 'list-item-ch',
          type: 'list-item-icon',
          icon: '/icon-support-ch-1.jpg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.1.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.1.title',
              ],
            },
            'recommendations.support.global.ch.service.1.content',
          ],
        },
        {
          id: 'list-item-2-ch',
          type: 'list-item-icon',
          icon: '/bg-hero-work-organisation-1.jpeg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.2.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.2.title',
              ],
            },
            'recommendations.support.global.ch.service.2.content',
          ],
        },
        {
          id: 'list-item-3-ch',
          type: 'list-item-icon',
          icon: '/icon-support-ch-3.jpg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.3.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.3.title',
              ],
            },
            'recommendations.support.global.ch.service.3.content',
          ],
        },
      ],
    },
  ],
  'de-ch': [
    {
      id: 'list-ch',
      type: 'ul',
      columns: 3,
      content: [
        {
          id: 'list-item-ch',
          type: 'list-item-icon',
          icon: '/icon-support-ch-1.jpg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.1.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.1.title',
              ],
            },
            'recommendations.support.global.ch.service.1.content',
          ],
        },
        {
          id: 'list-item-2-ch',
          type: 'list-item-icon',
          icon: '/bg-hero-work-organisation-1.jpeg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.2.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.2.title',
              ],
            },
            'recommendations.support.global.ch.service.2.content',
          ],
        },
        {
          id: 'list-item-3-ch',
          type: 'list-item-icon',
          icon: '/icon-support-ch-3.jpg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.3.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.3.title',
              ],
            },
            'recommendations.support.global.ch.service.3.content',
          ],
        },
      ],
    },
  ],
  'it-ch': [
    {
      id: 'list-ch',
      type: 'ul',
      columns: 3,
      content: [
        {
          id: 'list-item-ch',
          type: 'list-item-icon',
          icon: '/icon-support-ch-1.jpg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.1.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.1.title',
              ],
            },
            'recommendations.support.global.ch.service.1.content',
          ],
        },
        {
          id: 'list-item-2-ch',
          type: 'list-item-icon',
          icon: '/bg-hero-work-organisation-1.jpeg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.2.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.2.title',
              ],
            },
            'recommendations.support.global.ch.service.2.content',
          ],
        },
        {
          id: 'list-item-3-ch',
          type: 'list-item-icon',
          icon: '/icon-support-ch-3.jpg',
          classNames: {
            li: 'bg-white p-4',
            img: 'md:w-[80px]! md:h-[80px]!',
          },
          content: [
            {
              type: 'heading',
              content: [
                'recommendations.support.global.ch.service.3.heading',
              ],
            },
            {
              type: 'title',
              content: [
                'recommendations.support.global.ch.service.3.title',
              ],
            },
            'recommendations.support.global.ch.service.3.content',
          ],
        },
      ],
    },
  ],
  it: [
    {
      id: 'list-it',
      type: 'ul',
      content: [
        {
          id: 'list-item-it',
          type: 'list-item-icon',
          icon: '/icon-support-it-1.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.it.service.1.title',
              ],
            },
            'recommendations.support.global.it.service.1.content',
          ],
        },
        {
          id: 'list-item-2-it',
          type: 'list-item-icon',
          icon: '/icon-support-it-2.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.it.service.2.title',
              ],
            },
            'recommendations.support.global.it.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-it',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://assistenza360.axa.it/?utm_source=mind_health_self_check&utm_medium=referral&utm_campaign=homepage_assistenza360',
          content: 'recommendations.support.global.link',
          id: 'recommendations_it_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://login.axa.it/login-dhp?utm_source=mind_health_self_check&utm_medium=referral&utm_campaign=login_dhp',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_it_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  'es-mx': [
    {
      id: 'list-mx',
      type: 'ul',
      content: [
        {
          id: 'list-item-mx',
          type: 'list-item-icon',
          icon: '/icon-support-mx-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.mx.service.1.title',
              ],
            },
            'recommendations.support.global.mx.service.1.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-mx',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'tel:+525551693080',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_mx_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://cloud.email-axakeralty.mx/digital-mind-health-index',
          content: 'recommendations.support.global.link',
          id: 'recommendations_mx_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  es: [
    {
      id: 'list-es',
      type: 'ul',
      content: [
        {
          id: 'list-item-es',
          type: 'list-item-icon',
          icon: '/icon-support-es-1.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.es.service.1.title',
              ],
            },
            'recommendations.support.global.es.service.1.content',
          ],
        },
        {
          id: 'list-item-2-es',
          type: 'list-item-icon',
          icon: '/icon-support-es-2.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.es.service.2.title',
              ],
            },
            'recommendations.support.global.es.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-es',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axa.es/cliente/axa',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_es_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.es/servicios/moviles-videoconsulta',
          content: 'recommendations.support.global.link',
          id: 'recommendations_es_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  ja: [
    {
      id: 'list-ja',
      type: 'ul',
      content: [
        {
          id: 'list-item-ja',
          type: 'list-item-icon',
          icon: '/icon-support-ja-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.ja.service.1.title',
              ],
            },
            'recommendations.support.global.ja.service.1.content',
          ],
        },
        {
          id: 'list-item-2-ja',
          type: 'list-item-icon',
          icon: '/icon-support-ja-2.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.ja.service.2.title',
              ],
            },
            'recommendations.support.global.ja.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-ja',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axa.co.jp/customer/emma',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_ja_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.co.jp/',
          content: 'recommendations.support.global.link',
          id: 'recommendations_ja_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  fr: [
    {
      id: 'list-fr',
      type: 'ul',
      content: [
        {
          id: 'list-item-fr',
          type: 'list-item-icon',
          icon: '/icon-support-fr-1.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.service.1.title',
              ],
            },
            'recommendations.support.global.fr.service.1.content',
          ],
        },
        {
          id: 'list-item-2-fr',
          type: 'list-item-icon',
          icon: '/icon-support-fr-2.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.service.2.title',
              ],
            },
            'recommendations.support.global.fr.service.2.content',
          ],
        },
        {
          id: 'list-item-3-fr',
          type: 'list-item-icon',
          icon: '/icon-support-fr-3.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.fr.service.3.title',
              ],
            },
            'recommendations.support.global.fr.service.3.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-fr',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.angel.fr/',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_fr_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.fr/complementaire-sante/service-angel.html',
          content: 'recommendations.support.global.link',
          id: 'recommendations_fr_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  th: [
    {
      id: 'list-th',
      type: 'ul',
      content: [
        {
          id: 'list-item-th',
          type: 'list-item-icon',
          icon: '/icon-support-th-1.jpg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.th.service.1.title',
              ],
            },
            'recommendations.support.global.th.service.1.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-th',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.krungthai-axa.co.th/th/health-services/mind-health',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_th_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.krungthai-axa.co.th/th/health-services/mind-health',
          content: 'recommendations.support.global.link',
          id: 'recommendations_th_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  tr: [
    {
      id: 'list-tr',
      type: 'ul',
      content: [
        {
          id: 'list-item-tr',
          type: 'list-item-icon',
          icon: '/icon-support-tr-1.jpg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.service.1.title',
              ],
            },
            'recommendations.support.global.tr.service.1.content',
          ],
        },
        {
          id: 'list-item-3-tr',
          type: 'list-item-icon',
          icon: '/icon-support-tr-3.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.service.3.title',
              ],
            },
            'recommendations.support.global.tr.service.3.content',
          ],
        },
        {
          id: 'list-item-2-tr',
          type: 'list-item',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.tr.service.2.title',
              ],
            },
            'recommendations.support.global.tr.service.2.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-tr',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://www.axasigorta.com.tr/bireysel-islemler',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_tr_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axasigorta.com.tr/saglik-sigortalari',
          content: 'recommendations.support.global.link',
          id: 'recommendations_tr_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
  'en-ph': [
    {
      id: 'list-ph',
      type: 'ul',
      content: [
        {
          id: 'list-item-ph',
          type: 'list-item-icon',
          icon: '/icon-support-ph-1.svg',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'recommendations.support.global.ph.service.1.title',
              ],
            },
            'recommendations.support.global.ph.service.1.content',
          ],
        },
      ],
    },
    {
      id: 'buttons-group-ph',
      type: 'button-group',
      content: [
        {
          type: 'button',
          buttonType: 'link',
          variant: 'outline-primary',
          href: 'https://axa.mindyou.com.ph/login',
          content: 'recommendations.support.global.customer_link',
          id: 'recommendations_ph_customer',
          target: '_blank',
        },
        {
          type: 'button',
          buttonType: 'link',
          variant: 'primary',
          href: 'https://www.axa.com.ph/products/health-and-critical-illness',
          content: 'recommendations.support.global.link',
          id: 'recommendations_ph_notcustomer',
          target: '_blank',
        },
      ],
    },
  ],
};

function ContentRenderer({ content, className }) {
  const { t } = useTranslation();

  return content.map((item) => {
    switch (item.type) {
      case 'ul': {
        const cols = item.columns || 2;

        return (
          <ul key={item.id} className={`grid grid-cols-1 md:grid-cols-${cols} gap-5 md:gap-y-10 mt-12 lg:mt-16`}>
            <ContentRenderer content={item.content} />
          </ul>
        );
      }
      case 'list-item':
        return (
          <li key={item.id} className="bg-white p-4">
            <ContentRenderer content={item.content} />
          </li>
        );
      case 'list-item-icon': {
        const liClasses = classnames('flex gap-4 only:col-span-2', item.classNames?.li);
        const imgClasses = classnames('w-[73px] h-[73px] bg-white rounded-full object-cover shadow-sm', item.classNames?.img);

        return (
          <li key={item.id} className={liClasses}>
            <img src={item.icon} alt={item.alt} className={imgClasses} />
            <div className="flex flex-col grow min-w-1">
              <ContentRenderer content={item.content} />
            </div>
          </li>
        );
      }
      case 'button-group':
        return (
          <div key={item.id} className="flex flex-col gap-3 justify-center mt-4 md:flex-row">
            <ContentRenderer content={item.content} className="first:order-2 md:last:order-2" />
          </div>
        );
      case 'button': {
        const type = item.buttonType === 'link' ? Link : item.buttonType;

        return (
          <Button
            component={type}
            to={item.href}
            variant={item.variant}
            size="sm"
            id={item.id}
            target={item.target}
            className={className}
          >
            {t(item.content)}
          </Button>
        );
      }
      case 'title':
        return <div className="font-bold mt-3 first:mt-0"><ContentRenderer content={item.content} /></div>;
      case 'heading':
        return <div className="font-bold mb-4"><ContentRenderer content={item.content} /></div>;
      default:
        return (
          <p className="mt-2 first:mt-0 break-words">
            <Trans
              i18nKey={item}
              components={{
                Link: <TrackedLink className="text-axa-blue-400 underline" target="_blank" />,
              }}
            />
          </p>
        );
    }
  });
}

function Support() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const content = Config[lang?.toLowerCase?.()] || Config.default;

  return (
    <>
      <div className="flex flex-col items-center mx-auto text-center">
        <Type variant="h3">{t('recommendations.support.lead')}</Type>
        <Type variant="h2" className="mt-2.5">{t('recommendations.support.title')}</Type>
        <Type variant="h3" className="text-xs mt-1 text-left">{t('recommendations.support.subtitle')}</Type>
      </div>
      <ContentRenderer content={content} />
      <div className="mt-24 flex justify-center">
        <div className="flex gap-4 bg-white p-5">
          <img src="/icon-global.png" alt="" className="w-[73px] h-[73px] bg-white rounded-full object-cover shadow-sm" />
          <div className="flex flex-col gap-3 grow min-w-1">
            <Type variant="h1">
              <Trans
                i18nKey="methodology.title"
              />
            </Type>
            <p>
              <Trans
                i18nKey="methodology.content.1"
              />
            </p>
            <p>
              <Trans
                i18nKey="methodology.content.2"
                components={{
                  Link: <TrackedLink className="text-axa-blue-400 underline" target="_blank" id="methodology" />,
                }}
              />
            </p>
            <p>
              <Trans
                i18nKey="methodology.content.3"
                components={{
                  Link: <TrackedLink className="text-axa-blue-400 underline" id="mind_health_report" target="_blank" />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
