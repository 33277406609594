import { Amplify } from 'aws-amplify';
import { get, post } from 'aws-amplify/api';

const API_NAME = import.meta.env.VITE_AWS_API_NAME;
const requiresAuth = import.meta.env.VITE_AUTH_REQUIRED;

const amplifyConfig = {
  API: {
    REST: {
      [API_NAME]: {
        endpoint: import.meta.env.VITE_AWS_APIGATEWAY_URL,
      },
    },
  },
};

const amplifyOptions = {
  API: {
    REST: {},
  },
};

const API = {
  configure() {
    if (requiresAuth === 'true') {
      const accessToken = window.localStorage.getItem('access_token');

      amplifyOptions.API.REST.headers = async () => ({
        Authorization: `Bearer ${accessToken}`,
      });
    }

    Amplify.configure(amplifyConfig, amplifyOptions);
  },

  async parseResponse(response) {
    try {
      response = await response;
    } catch (err) {
      if (err?.response?.statusCode === 401) {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('sub');
        window.localStorage.removeItem('claims');
        window.localStorage.removeItem('exp');
        window.location.reload();
      }

      let errBody;

      try {
        errBody = JSON.parse(err?.response?.body);
      } catch (err) { // eslint-disable-line no-unused-vars
        // Ignore.
      }

      const error = new Error(errBody?.error?.message);
      error.statusCode = err?.response?.statusCode;
      error.code = errBody?.error?.code;

      throw error;
    }

    const body = await response.body.json();

    return body;
  },

  async post(endpoint, options = {}) {
    const operation = post({
      apiName: options.apiName || API_NAME,
      path: endpoint,
      options,
    });

    return API.parseResponse(operation.response);
  },

  async get(endpoint, options = {}) {
    const operation = get({
      apiName: options.apiName || API_NAME,
      path: endpoint,
      options,
    });

    return API.parseResponse(operation.response);
  },
};

API.configure();
export default API;
