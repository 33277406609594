import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import { Button } from '../../../../components';
import IconClock from '../../../../assets/icon-clock-thin.svg?react';

function TimeframeModal({ isOpen, toggle, content }) {
  const { t } = useTranslation();
  const ref = useRef(null);

  // A little bit of a hack to steal focus from the response cards.
  useEffect(() => {
    if (isOpen && ref.current) {
      ref.current.focus();
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={toggle}>
      <div className="text-center">
        <Type variant="h2">{t('timeframe.title')}</Type>
        <div className="flex flex-col gap-6 mt-4 items-center">
          <IconClock className="w-[115px] h-[115px]" />
          <p>{t(content)}</p>
        </div>
        <div className="flex flex-col items-center mt-9">
          <Button type="button" variant="primary" tabIndex="0" onClick={toggle} ref={ref}>
            {t('continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

TimeframeModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  content: PropTypes.string,
};

export default TimeframeModal;
