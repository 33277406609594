import { useLoaderData, useNavigate } from 'react-router';
import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Icon, Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import useStore from '../../../../store';
import {
  BackButton,
  Button,
  ButtonGroup,
  Hero,
  Link,
  Progress,
} from '../../../../components';
import { LayoutLanding } from '../../../../layouts';

const selector = (state) => [state.responses];

function Screening() {
  const { Screening } = useLoaderData();
  const navigate = useNavigate();
  const [responses] = useStore(useShallow(selector));
  const { t } = useTranslation();
  const hasDemographics = Screening.Sections[0].id === 'demographics';
  const count = hasDemographics ? Screening.Sections.length - 1 : Screening.Sections.length;
  let showBackButton = false;

  // TODO: Tidy this up - it would probably be better to base this on whether
  // there's more than one assessment available to the current tenant or not.
  switch (import.meta.env.VITE_DMHI_ENTITY) {
    case 'be':
      showBackButton = true;
      break;
    default:
  }

  return (
    <LayoutLanding hero={<Hero img={Screening.intro.background} />}>
      {showBackButton ? <BackButton variant="button" className="absolute top-0 left-0 ml-6 mt-4" /> : null}
      <Type variant="h1" className="text-center">{t(Screening.title)}</Type>
      <div className="md:w-[692px] flex self-center">
        <p>{t(Screening.subtitle, { count })}</p>
      </div>
      <div className="flex flex-col gap-3 max-w-[692px] w-full mx-auto">
        {Screening.Sections.map((section) => {
          const classes = classnames('px-5 py-6 shadow-xs rounded-sm bg-white', {
            'bg-axa-gray-100!': section.id === 'demographics',
            'border-axa-gray-300': section.id === 'demographics',
            border: section.id === 'demographics',
          });
          const progressClasses = classnames({
            'border-axa-gray-400': section.id === 'demographics',
            border: section.id === 'demographics',
          });
          const progress = section.questions.reduce((total, question) => {
            if (responses[question.promptId] !== undefined) {
              return total + 1;
            }

            return total;
          }, 0);

          return (
            <div key={section.id} className={classes}>
              <div className="flex justify-between items-center">
                <Type variant="h3">{t(section.title)}</Type>
                <p className="text-xs">{progress} / {section.questions.length}</p>
              </div>
              <Progress
                total={section.questions.length}
                current={progress}
                className="mt-3"
                barClassName={progressClasses}
              />
            </div>
          );
        })}
      </div>
      <ButtonGroup row>
        <Button
          variant="default"
          type="button"
          onClick={(e) => { e.preventDefault(); navigate(-1); }}
          size="sm"
          className="flex items-center gap-4 text-axa-blue-400"
          tabIndex="-1"
          id="back"
        >
          <Icon variant="navigate-before" className="inline fill-axa-blue-400" />
          {t('back')}
        </Button>
        <Button
          component={Link}
          to="section/1"
          variant="primary"
          id="continue"
        >
          {t('continue')}
        </Button>
      </ButtonGroup>
    </LayoutLanding>
  );
}

export { default as screeningLoader } from './loader';
export default Screening;
