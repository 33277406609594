import LandingGlobal from './components/landing-global';
import LandingLocal from './components/landing-local';

function Landing() {
  const entity = import.meta.env.VITE_DMHI_ENTITY;

  if (entity === 'global' || entity === undefined) {
    return (
      <LandingGlobal />
    );
  }

  return (
    <LandingLocal />
  );
}

export { default as landingLoader } from './loader';
export default Landing;
