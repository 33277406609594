import { useNavigate } from 'react-router';
import { Icon, Type } from '@mindstep-axa-dmhi/dmhi-frontend-components';
import useTranslate from '../../../../hooks/use-translate';
import { Button } from '../../../../components';
import Item from '../../components/item';

function InformationBE() {
  const navigate = useNavigate();
  const { trans, t } = useTranslate([
    'notice.be.content.2',
    'notice.be.content.3',
    'notice.be.content.4',
    'notice.be.content.5',
    'notice.be.content.6',
    'notice.be.content.7',
    'notice.be.content.8',
    'notice.be.content.9',
    'notice.be.content.10',
    'notice.be.content.11',
    'notice.be.content.12',
    'notice.be.content.13',
    'notice.be.content.14',
    'notice.be.content.15',
    'notice.be.content.16',
    'notice.be.content.17',
    'notice.be.content.18',
    'notice.be.content.19',
    'notice.be.content.20',
    'notice.be.content.21',
    'notice.be.content.22',
    'notice.be.content.23',
    'notice.be.content.24',
    'notice.be.content.25',
    'notice.be.content.26',
    'notice.be.content.27',
    'notice.be.content.28',
    'notice.be.content.29',
    'notice.be.content.30',
    'notice.be.content.31',
    'notice.be.content.32',
    'notice.be.content.33',
    'notice.be.content.34',
    'notice.be.content.35',
    'notice.be.content.36',
    'notice.be.content.37',
    'notice.be.content.38',
    'notice.be.content.39',
    'notice.be.content.40',
    'notice.be.content.41',
    'notice.be.content.42',
    'notice.be.content.43',
    'notice.be.content.44',
    'notice.be.content.45',
    'notice.be.content.46',
    'notice.be.content.47',
    'notice.be.content.48',
    'notice.be.content.49',
  ]);

  return (
    <div className="container mx-auto pt-8 md:pt-16 md:pb-16 px-4">
      <Button
        variant="link"
        onClick={(e) => { e.preventDefault(); navigate(-1); }}
        tabIndex="-1"
      >
        <Icon variant="navigate-before" className="inline fill-gray-800 mr-4" />
        {t('back')}
      </Button>

      <div className="flex flex-col">
        <Type variant="h1" className="text-right text-axa-blue-400! uppercase">{trans['notice.be.content.2']}</Type>
        <div className="flex flex-col gap-4 bg-axa-green/25 p-3 mt-6">
          <p>{trans['notice.be.content.3']}</p>
          <p>{trans['notice.be.content.4']}</p>
        </div>
        <Type variant="h2" className="text-right text-axa-blue-400! uppercase mt-8">{trans['notice.be.content.5']}</Type>
        <div className="flex flex-col gap-6 mt-8">
          <Item title={trans['notice.be.content.6']} content={[trans['notice.be.content.7']]} />
          <Item title={trans['notice.be.content.8']} content={[trans['notice.be.content.9'], trans['notice.be.content.10']]} />
        </div>
        <div className="flex flex-col gap-6 mt-8">
          <Item title={trans['notice.be.content.11']} content={[trans['notice.be.content.12']]} />
        </div>
        <div className="flex flex-col gap-6 mt-6 bg-axa-green/25 px-3 py-6">
          <Item
            title={trans['notice.be.content.13']}
            content={[
              trans['notice.be.content.14'],
              trans['notice.be.content.15'],
              trans['notice.be.content.16'],
            ]}
          />
          <Item title={t('notice.be.content.17')} content={[trans['notice.be.content.18']]} />
          <Item title={t('notice.be.content.19')} content={[trans['notice.be.content.20'], trans['notice.be.content.21']]} />
        </div>
        <div className="flex flex-col gap-6 mt-8">
          <Item title={trans['notice.be.content.22']} content={[trans['notice.be.content.23']]} />
          <Item title={trans['notice.be.content.24']} content={[trans['notice.be.content.25'], trans['notice.be.content.26'], trans['notice.be.content.27']]} />
          <Item title={trans['notice.be.content.28']} content={[trans['notice.be.content.29'], trans['notice.be.content.30'], trans['notice.be.content.31'], trans['notice.be.content.32']]} />
          <Item title={trans['notice.be.content.33']} content={[trans['notice.be.content.34']]} />
          <Item title={trans['notice.be.content.35']} content={[trans['notice.be.content.36']]} />
          <Item title={trans['notice.be.content.37']} content={[trans['notice.be.content.38']]} />
          <Item title={trans['notice.be.content.39']} content={[trans['notice.be.content.40']]} />
          <Item title={trans['notice.be.content.41']} content={[trans['notice.be.content.42'], trans['notice.be.content.43']]} />
          <Item title={trans['notice.be.content.44']} content={[trans['notice.be.content.45'], trans['notice.be.content.46']]} />
          <Item title={trans['notice.be.content.47']} content={[trans['notice.be.content.48'], trans['notice.be.content.49']]} />
        </div>
      </div>
    </div>
  );
}

export default InformationBE;
