import BgHeroOnboarding1 from '../../assets/bg-hero-onboarding-1.png';

export function HeroLanding() {
  return (
    <div
      className="bg-[image:var(--bg)] bg-cover bg-no-repeat h-[198px] w-full bg-center lg:h-auto"
      style={{
        '--bg': `url('${BgHeroOnboarding1}')`,
      }}
    />
  );
}

export function Hero({ img }) {
  return (
    <div
      className="bg-[image:var(--bg)] bg-cover bg-no-repeat h-[198px] w-full bg-center lg:h-auto"
      style={{
        '--bg': `url('${img}')`,
      }}
    />
  );
}
