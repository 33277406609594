import PropTypes from 'prop-types';
import classnames from 'classnames';

function ButtonGroup({ children, className, row = false }) {
  const classes = classnames('flex gap-4 justify-center w-full mx-auto md:w-52', className, {
    'flex-row': row,
    'flex-col': !row,
    'md:gap-6': row,
  });

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.node,
  row: PropTypes.bool,
};

export default ButtonGroup;
